//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
   data () {
     return {
       activeTab: 0,
       tabs: [
         {
           title: 'Reguler',
           active: true,
         },
         {
           title: 'Premium',
           active: false,
         },
       ],
     }
   },
   computed: {
     setPosition () {
       if (this.left) {
         return 'mr-auto'
       }

       if (this.right) {
         return 'ml-auto'
       }

       if (this.center) {
         return 'mx-auto'
       }

       return 'ml-auto'
     }
   },
   created () {
     this.setActive(0)
   },
   methods: {
     setActive (index) {
       this.tabs[this.activeTab].active = false
       this.tabs[index].active = true
       this.activeTab = index
     }
   }

}
