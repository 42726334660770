//
//
//
//
//
//
//

export default {
  name: 'UtilsFab',
    data () {
      return {
        show: false,
      }
    },
    mounted () {
      window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
      onScroll () {
        const currentScrollPosition = document.documentElement.scrollTop
        // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
        if (currentScrollPosition < 0) {
          return
        }

        if (currentScrollPosition > 80) {
          this.show = true
        } else {
          this.show = false
        }
      },
      toTop () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
}
