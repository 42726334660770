//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppPopup from "~/components/AppPopup.vue";
import FloatingWhatsapp from "~/components/utils/floatingWhatsapp.vue";
export default {
  components: { FloatingWhatsapp, AppPopup },
  data() {
    return {
      statistic: [
        { title: 'Pengguna Aktif', value: '-' },
        { title: 'Tamu Undangan', value: '-' },
        { title: 'Undangan Dibuat', value: '-' },
        { title: 'Template Tersedia', value: '-' },
      ],


      aboutData: [
        {
          title: 'Kelola Undangan Praktis',
          description: 'Kelola undangan lebih mudah cukup dengan pilih tema, lengkapi data undangan dan sebarkan.',
          icon: 'paper'
        },
        {
          title: 'Undangan digital penuh kesan',
          description: 'Undangan digital bisa disimpan dan tidak akan rusak, anda bisa membagikan momen undangan ini melalui whatapps, email dan lainnya sesuai kebutuhan anda.',
          icon: 'heart'
        },
        {
          title: 'Biaya lebih hemat',
          description: 'Perbandingan harga cetak undangan dengan online lebih hemat undangan online tidak perlu menambah biaya design, kertas, cetak dan kirim.',
          icon: 'cart'
        }
      ],
      openDialog: false
    }
  },
  fetchOnServer: false,
  async fetch() {
    await this.$home
      .getStatistic()
      .then((res) => {
        // console.log(res.data)
        this.statistic.forEach((el, index) => {
          if (el.title === 'Pengguna Aktif') {
            this.statistic[index].value = res.data.userActive
          } else if (el.title === 'Tamu Undangan') {
            this.statistic[index].value = res.data.guestInvited
          } else if (el.title === 'Undangan Dibuat') {
            this.statistic[index].value = res.data.invitationCreated
          } else if (el.title === 'Template Tersedia') {
            this.statistic[index].value = res.data.templateAvailable
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    contactAdmin() {
      const url = 'https://wa.me/6287818660928?text=Hai Admin, Saya ingin membuat undangan digital'
      this.openDialog = false;
      window.open(url, '_blank');
    }
  },
}
