//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      categories: [],
    }
  },
  async fetch () {
    await this.$invitation.getCategories()
    .then(res => {
      this.categories = res.data.data.map( el => {
        return {
          id: el.id,
          name: el.attributes.name,
          active: false
        }
      })
    })
    .catch(err => {
      console.log(err)
    })
  },
  methods: {
    setActiveCategory (value, name) {
      for (const category of this.categories) {
        if (category.name === name ) {
          category.active = value
        }
      }

      const activate = this.categories.filter(el => el.active === true).map(el => el.name)
      this.$emit('filter', activate)
    }
  },
  fetchOnServer: false
}
