//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      partners: [

      ]
    }
  },
  async fetch() {
    await this.$partner
      .get()
      .then((res) => {
        this.partners = res.data.data.map(data => {
          return {
            name: data.attributes.name,
            logo: data.attributes.logo.data.attributes.url,
            instagram: data.attributes.instagram,
          }
        })

        // this.partners.push({
        //   name: 'test',
        //   logo: 'https://ngondangid.s3.ap-southeast-3.amazonaws.com/Whats_App_Image_2022_05_23_at_2_34_01_PM_d6950eecea.jpeg'
        // })

      })
      .catch((err) => {
        console.log(err)
      })
  },
  fetchOnServer: false,
}
