//
//
//
//
//
//
//
//

export default {
  methods: {
    onClick() {
        const url = 'https://wa.me/6287818660928?text=Haloo%20Saya%20ingin%20mengetahui%20tentang%20ngondang.id'
        window.open(url, '_blank');

    }
  }
}
