export default {
  computed: {
    screenSize() {
      if (process.client) {
        const width = window.innerWidth;
        if (width < 768) {
          return 'sm';
        } else if (width < 1024) {
          return 'md';
        }
      }
    }
  }
}
