//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import screenSize from '~/plugins/screenSize';
export default {
  name: 'SwiperNuxt',
  mixins: [
    screenSize,
  ],
  data () {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: '1',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true
        }
      },
      items: []
    }
  },

  mounted() {
    this.getBanner()
  },

  methods: {
    async getBanner() {
      try {
        const response = await this.$misc.getBanners()
        this.items = response.data.data
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },

    redirectTo(url) {
      window.open(url, '_blank')
    },
    onSwiperRedied(swiper) {
      // console.log('Swiper redied!', swiper)
    },
    onSwiperSlideChangeTransitionStart() {
      // console.log('SwiperSlideChangeTransitionStart!')
    },
    onSwiperClickSlide(index, reallyIndex) {
      // console.log('Swiper click slide!', reallyIndex)
    },
  }
}
