//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-full md:max-w-md',
    }
  },
  methods: {
    closeModal () {
      this.$emit('input', false)
      this.$emit('close')
    },
  },
}
