//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data (){
    return {
      sortBy: 'date',
      filter: {
        category: ''
      },
      items: [
        {
          text: 'Terbaru',
          value: 'date'
        },
        // {
        //   text: 'Rating',
        //   value: 'rating'
        // },
      ],
      themes: [],
      params: {
        date: '',
        pageSize: 9,
        category: []
      }
    }
  },
  watch: {
    sortBy (value) {
      this.params.date = value
    },
  },
  methods: {
    setFilter(filter){
      this.filter.category = filter
      this.params.category = filter
    }
  }
}
