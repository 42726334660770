//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheButton from './TheButton.vue'

export default {
  name: 'PlansCard',
  components: { TheButton },
  props: {
    active: {
      default: 0,
      type: Number
    },
  },
  data () {
    return {
      plan_id : 0,
      plans: [

      ],
      openDialog: false
    }
  },
  
  async fetch() {
    await this.$package.getPackages()
    .then(response => {
    // this.plans = response.data
      // console.log(response.data)
      this.plans = this.swapArray(response.data.data, 1, 2)
    })
  },
  computed: {
    activePackagePage () {
      if (this.plans.length > 0) {
        if (this.active !== 1) {
          return this.plans.filter(el => el.attributes.name !== 'Istimewa')
        } else {
          return this.plans.filter(el => el.attributes.name === 'Istimewa')
        }
      }
      return []
    }
  },
  watch: {
    active (newVal) {
      if (newVal) {
        this.$fetch()
      }
    }
  },
  methods: {
    swapArray (array, index1, index2) {
      const temp = array[index1]
      array[index1] = array[index2]
      array[index2] = temp
      return array
    },    
    contactAdmin() {
      const url = 'https://wa.me/6287818660928?text=Hai Admin, Saya ingin membuat undangan digital'
      this.openDialog = false;
      window.open(url, '_blank');
    }
  },
  fetchOnServer: false
}
