import { render, staticRenderFns } from "./PlansCard.vue?vue&type=template&id=7a6a7002&"
import script from "./PlansCard.vue?vue&type=script&lang=js&"
export * from "./PlansCard.vue?vue&type=script&lang=js&"
import style0 from "./PlansCard.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingPackage: require('/opt/build/repo/components/Loading/Package.vue').default,TheButton: require('/opt/build/repo/components/TheButton.vue').default,ModalBasic: require('/opt/build/repo/components/Modal/Basic.vue').default})
