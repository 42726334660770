//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    openDialog: false,
    items: [],
    swiperOptions:
    {
      loop: true,
      slidesPerView: '1',
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        dynamicBullets: true
      }
    },
  }),

  mounted() {
    this.getPopup()
  },

  methods: {
    async getPopup() {
      try {
          const response = await this.$misc.getPopUpBanners()
          console.log(response)
          this.items = response.data.data
          if (this.items.length > 0) {
            this.openDialog = true
          }
        } catch (error) {
          console.log(error)
        }
    },
    closeModal() {
      this.openDialog = false
    },
  },
}
