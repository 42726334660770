import { render, staticRenderFns } from "./index.vue?vue&type=template&id=830d04b4&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=830d04b4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "830d04b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SocialHead: require('/opt/build/repo/components/SocialHead.vue').default,TheButton: require('/opt/build/repo/components/TheButton.vue').default,AppPopup: require('/opt/build/repo/components/AppPopup.vue').default,AppBanner: require('/opt/build/repo/components/AppBanner.vue').default,SectionsPlans: require('/opt/build/repo/components/Sections/Plans.vue').default,SectionsThemePick: require('/opt/build/repo/components/Sections/ThemePick.vue').default,SectionsOurPartners: require('/opt/build/repo/components/Sections/OurPartners.vue').default,UtilsFab: require('/opt/build/repo/components/utils/Fab.vue').default,ModalBasic: require('/opt/build/repo/components/Modal/Basic.vue').default})
