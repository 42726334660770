import { render, staticRenderFns } from "./Package.vue?vue&type=template&id=2f70b03f&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PulseLoading: require('/opt/build/repo/components/pulseLoading.vue').default})
